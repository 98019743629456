import { datadogRum } from '@datadog/browser-rum';

export const setDatadogRumUser = (
  id: string,
  email: string,
  mobile: string
) => {
  datadogRum.setUser({ id, email, mobile });
};

export const datadogRumSetup = () => {
  if (
    import.meta.env.VITE_REACT_APP_ENV &&
    ['local', 'development', 'production', 'staging'].includes(
      import.meta.env.VITE_REACT_APP_ENV
    )
  ) {
    // datadogRum.init({
    //   applicationId: '4b20e85f-d80a-4af4-876c-4d6722ea2962',
    //   clientToken: 'pub6cbc66f86951cccb4c4b20321e5224fb',
    //   site: 'datadoghq.eu',
    //   service: 'relata-webapp',
    //   env: import.meta.env.VITE_REACT_APP_ENV,
    //   // Specify a version number to identify the deployed version of your application in Datadog
    //   // version: '1.0.0',
    //   sessionSampleRate: 100,
    //   sessionReplaySampleRate: 100,
    //   trackUserInteractions: true,
    //   trackResources: true,
    //   trackLongTasks: true,
    //   defaultPrivacyLevel: 'allow',
    // });

    // datadogRum.startSessionReplayRecording();
  }
};

export const publishErrorToDatadog = (error: Error) => {
  datadogRum.addError(error);
};
