import React, { useState, SyntheticEvent, useMemo } from 'react';

import {
  fallbackImageUrl,
  getCloudFrontUrlFromResourceUrl,
} from '@/constants/urls';

interface IImageProps {
  src: string;
  fallbackSrc?: string;
  alt?: string;
  imgClass?: string;
  containerClass?: string;
  lazyLoading?: 'lazy' | 'eager' | undefined; // Update the type here
}

const Image = (props: IImageProps) => {
  const {
    src,
    fallbackSrc = fallbackImageUrl,
    alt = 'Image',
    imgClass = '',
    containerClass = '',
    lazyLoading = 'eager',
  } = props;
  const [loading, setLoading] = useState(true);

  const handleImageError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    const target = e.target as HTMLImageElement;
    target.onerror = null;
    target.src = fallbackSrc;
  };

  const handleImageLoad = () => {
    setLoading(false);
  };

  const cloudFrontSrc = useMemo(() => {
    return getCloudFrontUrlFromResourceUrl(src);
  }, [src]);

  return (
    <div
      className={`${containerClass} image-container ${
        loading ? 'loading-spin-container' : ''
      }`}>
      <img
        src={cloudFrontSrc}
        alt={alt}
        loading={lazyLoading}
        className={`${imgClass} image-container__image`}
        onError={handleImageError}
        onLoad={handleImageLoad}
      />
      {loading && <span className='loading-spin'></span>}
    </div>
  );
};

export default Image;
